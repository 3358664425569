import { MessageId } from 'common/i18n';
import jmoment from 'moment-jalaali';
import { redirect } from 'next/dist/server/api-utils';

const arabicDigits = { '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4', '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9' };
const persianDigits = { '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9' };
export const getLatinDigits = (value: number | string) => value.toString().split('').map((d: string) => arabicDigits[d] || persianDigits[d] || d).join('');

const phoneRegx = /^\+?(00)?(98)?0?(9)(\d{9})$/;
const emailRegx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export type ValidationOptions = Record<string, string | number>;
export type ValidationError = [message: MessageId, options?: ValidationOptions];

export type Validator = (value: string | number, constraint?: string | number) => boolean;
export type ValidatorKey =
  'isPhone'
  | 'isEmail'
  | 'isDate'
  | 'required'
  | 'minlength'
  | 'maxlength'
  | 'min'
  | 'max'
  | 'equalTo'
  | 'isName'
  | 'isGender';
export type ValidationRule = [key: ValidatorKey, ...message: ValidationError];
export type Validators = { [key in ValidatorKey]: Validator }

export type ValidationDefinition = { [key: string]: ValidationRule[] }
export type ValidationErrors = { [key: string]: ValidationError }

const validators: Validators = {
  isPhone: (value: number) => (value ? phoneRegx.test(getLatinDigits(value)) : true),
  isEmail: (value: string) => (value ? emailRegx.test(value) : true),
  isName: (value: string) => (!!value),
  isGender: (value: string) => (!!value),
  isDate: (value: string) => (value ? jmoment(getLatinDigits(value), 'jYYYY/jMM/jDD').isValid() : true),
  required: (value: string | number) => !!value,
  minlength: (value: string, length: number) => (value ? String(value).length >= Number(length) : true),
  maxlength: (value: string, length: number) => (value ? String(value).length <= Number(length) : true),
  min: (value: number, min: number) => (value ? Number(getLatinDigits(value)) >= Number(min) : true),
  max: (value: number, max: number) => (value ? Number(getLatinDigits(value)) <= Number(max) : true),
  equalTo: (value: string | number, comparison: string | number) => (value ? value === comparison : true),
};

const getValidator = (key: ValidatorKey): Validator => (validators[key]);

export const validate = (value: string | number, rules: ValidationRule[]): ValidationError | undefined => {
  if (!rules) return;
  const firstError = rules.find(([validator, _, options]) => !getValidator(validator)(value, options && Object.values(options)[0]));
  if (firstError) {
    const [_, message, options] = firstError;
    return [message, options];
  }
};

export const validateAll = (
  form: { [key: string]: string | number | null | undefined },
  validations = {},
): [isValid: boolean, errors: ValidationErrors] => {
  const errors = {};
  Object.entries(form).forEach(([name, value]: [string, string | number]) => {
    errors[name] = validate(value, validations[name]) || '';
  });
  return [!Object.values(errors).join('').length, errors];
};

const allowedPaths = [
  '/',
  '/version',
  '/rules',
  '/payment',
  '/packages',
  '/live-stream',
  '/faq',
  '/direct-debit',
  '/dd-agreement',
  '/contact-us',
  '/cinema-online',
  '/apps',
  '/agent',
  '/about',
  '/500',
  '/404',
  '/artists/[id]',
  '/categories',
  '/categories/[id]',
  '/contents',
  '/contents/[id]',
  '/login',
  '/login/[id]',
  '/mobile/contact-us',
  '/packages/successGift',
  '/packages/successPayment',
  '/play/[id]',
  '/profile',
  '/profile/choose',
  '/profile/info',
  '/profile/name',
  '/profile/pin',
  '/profile/remove',
  '/profile/reset-pin',
  '/profile/type',
  '/register',
  '/register/[id]',
  '/tickets/app-payment',
  '/tickets/success',
  '/tickets/[id]',
  '/tv',
  '/tv/[id]',
  '/user/bookmarks',
  '/user/continue-watching',
  '/user/devices',
  '/user/edit',
  '/user/history',
  '/user/menu',
  '/user/password',
  '/user/subscriptions',
  '/user/tickets',
];

export const validateRedirectRoutes = (redirect: string) => {
  return allowedPaths.some((path) => {
    const pattern = new RegExp(`^${path.replace('[id]', '[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*')}(\\?code=[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)?$`);
    const redirectUrl = redirect.split('/').length > 3 ? '/' + redirect.split('/')[1] + '/' + redirect.split('/')[2] : redirect;
    return pattern.test(redirectUrl);
  });
};

export default { validators, validate, validateAll, validateRedirectRoutes };
