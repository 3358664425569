import React from 'react';
import InputMask, { Props } from 'react-input-mask';
import TextField, { TextFieldProps } from './TextField';

declare module 'react-input-mask' {
  export interface Props {
    maskChar?: string | null;
  }
}

export const defaultMaskChar = '–';

export type MaskedInputProps = Props & TextFieldProps;
const MaskedInput = (props: MaskedInputProps) => {
  const {
    value, onChange, type, onBlur: handleBlur, mask, maskPlaceholder, disabled,
    alwaysShowMask, beforeMaskedStateChange, maskChar = defaultMaskChar, inputRef,
    ...rest
  } = props;

  const handleChange = (event) => {
    const name = event.target.name;
    const re = new RegExp(maskChar || defaultMaskChar, 'g');
    const value = event.target.value.replace(re, '');
    try {
      const nativeEvent = event.nativeEvent || event;
      const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);
      Object.defineProperty(clonedEvent, 'target', {
        writable: true,
        value: { value, name },
      });
      onChange?.(clonedEvent);
    } catch (e) {
      onChange?.({ target: { value, name } } as any);
    }
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      ref={inputRef}
      type={type}
      align="left"
      dir="ltr"
      {...rest}
    />
  );

  // return (
  //   <InputMask
  //     value={value}
  //     onChange={handleChange}
  //     onBlur={handleBlur}
  //     mask={mask}
  //     maskChar={maskChar}
  //     maskPlaceholder={maskPlaceholder}
  //     alwaysShowMask={alwaysShowMask}
  //     disabled={disabled}
  //     beforeMaskedStateChange={beforeMaskedStateChange}
  //     inputRef={inputRef}
  //   >
  //     {(inputProps) => <TextField dir="ltr" align="right" {...inputProps} disabled={disabled} {...rest} />}
  //   </InputMask>
  // );
};

export default MaskedInput;
