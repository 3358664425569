import { useState, useEffect } from 'react';

interface TimeProps {
  minutes: string;
  seconds: string;
}

const timeDiff = (diff: number): TimeProps => {
  if (diff < 0) return { minutes: '00', seconds: '00' };

  const mm = Math.floor(diff / 60);
  const ss = Math.floor(diff - (mm * 60));

  return {
    seconds: String(ss).padStart(2, '0'),
    minutes: String(mm).padStart(2, '0'),
  };
};

interface TimerProps {
  time: number;
  onFinish?: () => void;
}

export default function Timer({ time, onFinish }: TimerProps) {
  const [counter, setCounter] = useState(time);

  const remaining = timeDiff(counter);

  useEffect(() => {
    if (counter === 0) {
      onFinish?.();
    } else {
      const timeTimeout = setTimeout(() => {
        if (counter >= 0) {
          setCounter((prev) => prev - 1);
        }
      }, 1000);
      return () => {
        clearTimeout(timeTimeout);
      };
    }
  }, [counter, onFinish]);

  useEffect(() => {
    setCounter(time);
  }, [time]);

  return <>{remaining.seconds} : {remaining.minutes}</>;
}
